<template>
  <div class="grid grid-cols-2 gap-2 my-2">
    <EditableCard
      type="comment"
      title="Medical conditions"
    >
      <div class="py-4">
        <ToggleList title="Migraines (Jun 28, 2020)">
          <p class=" text-gray-500">Bronchitis, not specified as acute or chronic, Esophageal, patient not hospitalised. Read more</p>
        </ToggleList>
        <ToggleList title="Asthma">
          <p class=" text-gray-500">Bronchitis, not specified as acute or chronic, Esophageal, patient not hospitalised. Read more</p>
        </ToggleList>
        <ToggleList title="Migraines (Jun 28, 2020)">
          <p class=" text-gray-500">Bronchitis, not specified as acute or chronic, Esophageal, patient not hospitalised. Read more</p>
        </ToggleList>
      </div>
    </EditableCard>
    <EditableCard
      type="pills"
      title="Medications"
    >
      <div class="py-4">
        <ToggleList title="Migraines (Jun 28, 2020)">
          <p class=" text-gray-500">Bronchitis, not specified as acute or chronic, Esophageal, patient not hospitalised. Read more</p>
        </ToggleList>
        <ToggleList title="Asthma">
          <p class=" text-gray-500">Bronchitis, not specified as acute or chronic, Esophageal, patient not hospitalised. Read more</p>
        </ToggleList>
        <ToggleList title="Migraines (Jun 28, 2020)">
          <p class=" text-gray-500">Bronchitis, not specified as acute or chronic, Esophageal, patient not hospitalised. Read more</p>
        </ToggleList>
      </div>
    </EditableCard>
    <EditableCard
      type="add"
      title="Allergies"
    >
      <div class="py-4">
        <ToggleList title="Migraines (Jun 28, 2020)">
          <p class=" text-gray-500">Bronchitis, not specified as acute or chronic, Esophageal, patient not hospitalised. Read more</p>
        </ToggleList>
        <ToggleList title="Asthma">
          <p class=" text-gray-500">Bronchitis, not specified as acute or chronic, Esophageal, patient not hospitalised. Read more</p>
        </ToggleList>
        <ToggleList title="Migraines (Jun 28, 2020)">
          <p class=" text-gray-500">Bronchitis, not specified as acute or chronic, Esophageal, patient not hospitalised. Read more</p>
        </ToggleList>
      </div>
    </EditableCard>
    <EditableCard title="Social History">
      <div class="py-4">
        <ToggleList title="Migraines (Jun 28, 2020)">
          <p class=" text-gray-500">Bronchitis, not specified as acute or chronic, Esophageal, patient not hospitalised. Read more</p>
        </ToggleList>
        <ToggleList title="Asthma">
          <p class=" text-gray-500">Bronchitis, not specified as acute or chronic, Esophageal, patient not hospitalised. Read more</p>
        </ToggleList>
        <ToggleList title="Migraines (Jun 28, 2020)">
          <p class=" text-gray-500">Bronchitis, not specified as acute or chronic, Esophageal, patient not hospitalised. Read more</p>
        </ToggleList>
      </div>
    </EditableCard>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>