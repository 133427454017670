<template>
  <div class="w-full h-full border border-serenity-subtle-border border-dashed flex items-center justify-center">
    <img
      src="@/assets/img/add--filled 1.svg"
      alt=""
    >
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>